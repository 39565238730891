import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" }
const _hoisted_2 = { class: "py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8" }
const _hoisted_3 = {
  key: 0,
  class: "text-xl mt-4 mb-2"
}
const _hoisted_4 = { class: "shadow overflow-hidden border-b border-gray-200 sm:rounded-lg" }
const _hoisted_5 = { class: "container w-full text-center bg-white border-t-0 rounded-xl rounded-t-none" }
const _hoisted_6 = { class: "min-w-full divide-y divide-gray-200" }
const _hoisted_7 = { class: "whitespace-nowrap" }
const _hoisted_8 = { class: "divide-y divide-gray-200" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_course_item = _resolveComponent("course-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.type !== _ctx.type_mod && _ctx.type !== _ctx.type_pending)
          ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString(_ctx.course_list.year.start_year) + " / " + _toDisplayString(_ctx.course_list.year.end_year), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("ul", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.course_list.courses, (course) => {
                    return (_openBlock(), _createBlock(_component_course_item, {
                      key: course._id,
                      course: course,
                      type: _ctx.type
                    }, null, 8, ["course", "type"]))
                  }), 128))
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}
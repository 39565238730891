import { request } from "@/requests/api-request";
import { AxiosError } from "axios";
import { AlertSeverities, showAlert } from "@/helpers/alerts";
import { getHeaderAuth } from "../handlers-helpers";
import { CourseModType, CourseTaughtType, CourseType } from "@/types/course/course-type";

export async function courseListTaughtHandler(): Promise<Array<CourseTaughtType> | void> {
  return await request(undefined, "GET", "course/taught", undefined, undefined, getHeaderAuth())
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus se přihlásit ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        return response.payload;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(error);
      }
    });
}

export async function courseListEnrolledHandler(): Promise<Array<CourseType> | void> {
  return await request(undefined, "GET", "course/my_courses", undefined, undefined, getHeaderAuth())
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus se přihlásit ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        return response.payload;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(error);
      }
    });
}

export async function courseListAvailibleHandler(): Promise<Array<CourseType> | void> {
  return await request(undefined, "GET", "course/available", undefined, undefined, getHeaderAuth())
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus se přihlásit ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        return response.payload;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(error);
      }
    });
}

export async function courseListModHandler(): Promise<Array<CourseModType> | void> {
  return await request(undefined, "GET", "course/mod", undefined, undefined, getHeaderAuth())
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus se přihlásit ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        return response.payload;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(error);
      }
    });
}

export async function courseListPendingHandler(): Promise<Array<CourseType> | void> {
  return await request(undefined, "GET", "course/pending", undefined, undefined, getHeaderAuth())
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus se přihlásit ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        return response.payload;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(error);
      }
    });
}


import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { coursePatchHandler } from "../handlers/course/course-plain-handlers";
import { CoursePatchFormType, CourseType } from "../types/course/course-type";
import { StatusEnum } from "../types/enum/status-enum";
import { TypeSubjectEnum } from "../types/enum/type-course";
import Category from "../components/Category.vue";
import { isLoggedIn } from "../handlers/handlers-helpers";
import { AlertSeverities, showAlert } from "@/helpers/alerts";

@Options({
  components: { Category },
})
export default class CourseCard extends Vue {
  @Prop() readonly course!: CourseType;
  @Prop() readonly type!: TypeSubjectEnum;

  private type_taught: TypeSubjectEnum = TypeSubjectEnum.TAUGHT;
  private type_mod: TypeSubjectEnum = TypeSubjectEnum.MOD;
  private type_general: TypeSubjectEnum = TypeSubjectEnum.GENERAL;
  private type_pending: TypeSubjectEnum = TypeSubjectEnum.PENDING;

  private course_approved: StatusEnum = StatusEnum.APPROVED;
  private course_rejected: StatusEnum = StatusEnum.REJECTED;

  async handlePatch(status: StatusEnum): Promise<void> {
    if (isLoggedIn()) {
      const payload: CoursePatchFormType = {
        status: status,
      };
      const response = await coursePatchHandler(payload, this.course._id);
      if (response === undefined) return;
      if (status === StatusEnum.APPROVED) {
        showAlert("Úspěch", "Kurz úspěšně potvrzen.", AlertSeverities.success);
      } else {
        showAlert("Úspěch", "Kurz úspěšě zamítnut", AlertSeverities.success);
      }
      this.$router.go(0);
    }
  }
}

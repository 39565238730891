
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import CourseItem from "./CourseItem.vue";
import { CourseType } from "../types/course/course-type";
import { TypeSubjectEnum } from "../types/enum/type-course";

@Options({
  components: {
    CourseItem,
  },
})
export default class CourseList extends Vue {
  @Prop() readonly course_list!: Array<CourseType>;
  @Prop() readonly type!: TypeSubjectEnum;
  private type_mod: TypeSubjectEnum = TypeSubjectEnum.MOD;
  private type_pending: TypeSubjectEnum = TypeSubjectEnum.PENDING;
}
